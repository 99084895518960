<template>

  <form>

    <AppHeader theme="white">
      <template v-slot:left>
        <router-link :to="{name: 'contact-form'}"><img src="@/assets/img/icon_back.png"></router-link>
      </template>
      お問い合わせ内容確認
    </AppHeader>

    <section class="contents">

      <div class="contentInner">

        <div class="white_bg_area2">

          <div class="mb_20">お問い合わせ内容を確認して、「送信」ボタンをタップしてください。</div>

          <div class="contact_item">
            <div class="item_label">お名前</div>
            <div>{{ input_name }}</div>
          </div>

          <div class="contact_item">
            <div class="item_label">メールアドレス</div>
            <div>{{ input_email }}</div>
          </div>

          <div class="contact_item">
            <div class="item_label">お問い合わせの種類</div>
            <div>{{ input_type }}</div>
          </div>

          <div class="contact_item">
            <div class="item_label">お問い合わせ内容</div>
            <div><pre>{{ input_content }}</pre></div>
          </div>

          <div class="bt_form">
            <SingleSubmitButton type="button" :onclick="send">送信</SingleSubmitButton>
          </div>

        </div><!-- .white_bg_area -->

      </div><!--.contentInner-->

    </section>

    <AppFooter theme="yellow" fixed></AppFooter>

  </form>

</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import SingleSubmitButton from '@/components/SingleSubmitButton';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
export default {
  name: 'ContactConfirm',
  components: {AppHeader, AppFooter, SingleSubmitButton},
  props: {
    input_name: String,
    input_email: String,
    input_type: String,
    input_content: String,
  },
  created () {
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.$router.push({name:'contact-form'});
    }
  },
  mixins: [validationMixin],
  validations: {
    input_name: {
      required,
    },
    input_email: {
      required,
    },
    input_type: {
      required,
    },
    input_content: {
      required,
    },
  },
  methods: {
    send () {
      return new Promise((resolve) => {
        // setTimeout(() => {
          this.$http.post('contacts', {
            name: this.input_name, email: this.input_email, type: this.input_type, content: this.input_content
          });
          this.$router.push({name: 'contact-send'});
          resolve();
        // }, 1000);
      });
    }
  }
}
</script>
