<template>
  <header>
    <ul class="page_head" :class="`head_${theme}`">
      <li class="menuL">
        <span v-if="headerBack">
          <router-link class="page_head_link" :to="headerBack" replace>
            <img v-if="theme == 'gray'" src="@/assets/img/icon_back.png" alt="Back icon">
            <img v-else src="@/assets/img/icon_back_white.png" alt="Back icon white">
          </router-link>
        </span>
        <slot name="left"></slot>
      </li>
      <li class="menuC"><h1 class="page_head_h1" :class="{ 'text-black': isMyPageActive }">
        <slot></slot>
      </h1></li>
      <li class="menuR">
        <slot name="right">
          <input id="drawer-checkbox" type="checkbox" @change="isMenuOpen = $event.target.checked">
          <label id="drawer-icon" for="drawer-checkbox" @click="toggleMenu"><span class="up" :class="{'is-hidden': isMenuOpen}"></span><span class="bottom"></span></label>
          <label id="drawer-close" for="drawer-checkbox"></label>
          <div id="drawer-content" :class="`drawer_${theme}`">
            <nav>
              <div class="user_profile">
                <div class="user_photo"><img :src="image" alt=""></div>
                <div class="user_nickname">{{ loginUser.nick }}</div>
              </div>
              <ul class="menu01" v-if="isLogin">
                <li class="talk"><router-link :to="{name:'home'}">トーク</router-link></li>
                <li class="shop" style="opacity: 0.1;"><a href="javascript:void(0)">ショップ</a></li>
                <li class="mypage"><router-link :to="{name:'mypage'}">わたしのこと</router-link></li>
                <li class="tutorial"><router-link :to="{name:'tutorial'}">すてきなせかいとは</router-link></li>
                <li class="news"><router-link :to="{name:'news'}">お知らせ</router-link></li>
                <li class="contact"><router-link :to="{name:'contact-form'}">お問い合わせ</router-link></li>
                <li class="logout"><router-link :to="{name:'logout'}">ログアウト</router-link></li>
              </ul>

              <ul class="menu02">
                <li class=""><router-link :to="{name:'login'}">TOP</router-link></li>
                <li class="faq"><router-link :to="{name:'faq'}">FAQ</router-link></li>
                <li class="terms"><router-link :to="{name:'rule'}">利用規約</router-link></li>
                <li class="privacy"><router-link :to="{name:'privacy'}">プライバシーポリシー</router-link></li>
                <li class="tokushoho"><router-link :to="{name:'law'}">特定商取引法に基づく表記</router-link></li>
              </ul>

              <div class="menu_sns_title">公式SNS</div>
              <div class="menu_sns">
                <a href="https://twitter.com/sutekinasekai22" target="_blank"><img src="@/assets/img/twitter.png" alt="Twitterロゴ"></a>
                <a href="https://www.facebook.com/すてきなせかい-114401321270645/?ref=pages_you_manage" target="_blank"><img src="@/assets/img/facebook.png" alt="Facebookロゴ"></a>
                <a href="https://www.instagram.com/sutekinasekai.22/" target="_blank"><img src="@/assets/img/instagram.png" alt="Instagramロゴ"></a>
              </div>
            </nav>
          </div>
        </slot>
      </li>
    </ul>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  props: {
    theme: String,
  },
  computed: {
    isLogin () {
      return this.$store.getters.isLogin;
    },
    loginUser () {
      return this.$store.state.loginUser;
    },
    isMyPageActive() {
      return this.$route.path.endsWith('/my-page');
    },
    image () {
      if (this.loginUser.image) {
        return `${process.env.VUE_APP_API_ROOT}../${this.loginUser.image}`;
      } else {
        return `${process.env.VUE_APP_API_ROOT}../storage/images/users/add_photo2.png`;
      }
    },
    headerBack () {
      return this.$route.meta.headerBack;
    },
    currentPageStyles() {
    if (this.$route.path === '/my-page') {
      return {
        color: 'blue'
      };
    } else if (this.$route.path === '/pageB') {
      return {
        color: 'red'
      };
    }
    return {};
  }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  }
}
</script>

<style>
.page_head {
  height: 42px;
  overflow: hidden;
  background-color: #333333;
}
.page_head_link {
  color:white !important;
  font-size: 30px;
  text-decoration: none !important;
}
.text-black {
  color: black!important;
}
.is-hidden {
  display: none!important;
}
.page_head.head_gray .menuL {
  color: #262626;
}
</style>
